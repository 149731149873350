/* eslint-disable prettier/prettier */
import AniLink from "gatsby-plugin-transition-link/AniLink"
import React from 'react'
import menu from '../assets/icons/menu.svg'
import '../components/layout.css'
import Logo from '../images/logo.svg'
import swan from '../assets/baby-pack/61-swan.svg'

class Header extends React.Component {
  constructor(props, context) {
    super(props, context)

    this.state = {
      isMenuOpen: false,
      url: '',
    }

    this.toggleMenu = this.toggleMenu.bind(this)
  }

  // this.state?.url?.includes('my-approach') ? 'bg-blue-300' : 'bg-salmony-300'
  toggleMenu() {
    this.setState((state) => ({
      isMenuOpen: !state.isMenuOpen,
    }))
  }

  render() {
    return (
      <header>
        <div
          className={`flex flex-wrap ${this.state?.url?.includes('my-approach') ? 'bg-blue-300' : 'bg-lilas'
            } md:bg-salmony-0 items-center justify-between md:justify-center md:flex-col xl:justify-between px-4`}
        >
          <AniLink fade
            to="/"
            className="flex items-center py-2 text-lg text-gray-800 font-header md:text-custom-purple md:text-base"
          >
            <img
              src={Logo}
              alt="logo principal"
              className="inline h-24 mr-2 align-baseline transition-all transform rotate-0 hover:-rotate-10 transition-750"
            />
            <p className="lg:text-2xl text-custom-purple">
              Bébé au pays du sommeil
              <br />
              <b>Anna Volosova</b>, consultante certifiée
            </p>
            {/* <img className="h-16" src={logo} alt="Logo du site web"></img> */}
          </AniLink>
          <img className="h-8 md:hidden" src={menu} alt="Menu" onClick={this.toggleMenu}></img>
          <nav className="hidden mt-4 md:flex-col md:flex lg:w-auto lg:text-xl">
            {/* <Link
              activeStyle={{ color: '#A346F1' }}
              className="px-2 py-1 font-semibold text-gray-700 rounded nav-links transition-bg transition-750 md:rounded-full md:px-4"
              to="/"
            >
              Accueil
            </Link> */}
            <div id="upper-nav" className="flex justify-around">
              <AniLink fade
                className="px-2 py-1 font-semibold text-gray-700 rounded nav-links transition-bg transition-750 md:rounded-full md:px-4"
                to="/about-me/"
                activeStyle={{ color: '#A346F1' }}
              >
                Qui suis-je
              </AniLink>
              <AniLink fade
                className="px-2 py-1 font-semibold text-gray-700 rounded nav-links transition-bg transition-750 md:rounded-full md:px-4"
                to="/my-approach/"
                activeStyle={{ color: '#A346F1' }}
              >
                Mon approche
              </AniLink>
              <AniLink fade to="/pricing/"
                activeStyle={{ color: '#A346F1' }}
                className="px-2 py-1 font-semibold text-gray-700 rounded nav-links transition-bg transition-750 md:rounded-full md:px-4"
              >

                {/* <Link> */}
                Prestations
              {/* </Link> */}
              </AniLink>
            </div>
            <div id="lower-nav" className="flex justify-around mt-2">
            <AniLink fade
                className="px-2 py-1 font-semibold text-gray-700 rounded nav-links transition-bg transition-750 md:rounded-full md:px-4"
                to="/feedbacks/"
                activeStyle={{ color: '#A346F1' }}
              >
                Témoignages
              </AniLink>
              <AniLink paintDrip color="rebeccapurple" duration={0.7}
                className="px-2 py-1 font-semibold text-gray-700 rounded nav-links transition-bg transition-750 md:rounded-full md:px-4"
                to="/contact-form/"
                activeStyle={{ color: '#A346F1' }}
              >
                Me contacter
              </AniLink>
              <AniLink paintDrip color="rebeccapurple" duration={0.7}
                className="px-2 py-1 font-semibold text-gray-700 rounded nav-AniLinks transition-bg transition-750 md:rounded-full md:px-4"
                to="/blog"
                activeStyle={{ color: '#A346F1' }}
              >
                Blog
              </AniLink>
              <AniLink fade
                className="px-2 py-1 font-semibold text-gray-700 rounded nav-links transition-bg transition-750 md:rounded-full md:px-4"
                to="/resources"
                activeStyle={{ color: '#A346F1' }}
              >
                Ressources
              </AniLink>
            </div>
          </nav>
        </div>

        {this.state.isMenuOpen && (
          <nav
            className={`flex flex-col ${this.state?.url?.includes('my-approach') ? 'bg-blue-300' : 'bg-lilas'
              } px-4 py-2 md:hidden`}
          >
            <AniLink fade className="py-1 pl-2 font-semibold text-gray-800 rounded hover:bg-custom-purple" to="/">
              Accueil
            </AniLink>
            <AniLink fade
              className="py-1 pl-2 font-semibold text-gray-800 rounded hover:bg-custom-purple"
              to="/about-me/"
            >
              Qui suis-je
            </AniLink>
            <AniLink fade
              className="py-1 pl-2 font-semibold text-gray-800 rounded hover:bg-custom-purple"
              to="/my-approach/"
            >
              Mon approche
            </AniLink>
            <AniLink fade
              className="py-1 pl-2 font-semibold text-gray-800 rounded hover:bg-custom-purple"
              to="/pricing/"
            >
              Prestations
            </AniLink>
            <AniLink fade
              className="py-1 pl-2 font-semibold text-gray-800 rounded hover:bg-custom-purple"
              to="/feedbacks/"
            >
              Témoignages
            </AniLink>
            <AniLink paintDrip color="rebeccapurple" duration={0.7}
              className="py-1 pl-2 font-semibold text-gray-800 rounded hover:bg-custom-purple"
              to="/contact-form/"
            >
              Me contacter
            </AniLink>
            <AniLink paintDrip color="rebeccapurple" duration={0.7} className="py-1 pl-2 font-semibold text-gray-800 rounded hover:bg-custom-purple" to="/blog">
              Blog
            </AniLink>
            <AniLink fade
              className="py-1 pl-2 font-semibold text-gray-800 rounded hover:bg-custom-purple"
              to="/resources"
            >
              Ressources
            </AniLink>
          </nav>
        )
        }

        {/* //!! this banner was used when Anna was off */}

        {/* <div className="items-center max-w-4xl p-4 m-4 text-xl tracking-wide text-gray-800 rounded-lg bg-salmony-300 md:flex md:flex-row md:mx-auto md:px-4 font-muli md:text-lg lg:text-lg qhd:text-xl">
          <img src={swan} alt="image de cygne" className="hidden h-20 mr-4 md:inline" />
          <span>
            « Chers parents, jusqu’au mois d’avril je serai dans l’impossibilité d’assurer le conseil et le
            suivi car l’arrivée de mon petit va me prendre tout mon temps. Je vous annoncerai mon retour sur les
            réseaux sociaux et sur mon site web avant de démarrer »
          </span>
        </div> */}
      </header>
    )
  }
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
