import { graphql, Link, useStaticQuery } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import 'typeface-mali'
import 'typeface-muli'
import Logo from '../images/logo.svg'
// import beachBall from '../assets/baby-pack/beach_ball.svg'
import facebook from '../assets/social-media/013-facebook.svg'
import instagram from '../assets/social-media/014-instagram.svg'
import email from '../assets/social-media/043-email.svg'
import familySleepInstitute from '../images/fsi.png'
import Header from './header'
import './layout.css'

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <div className={`${'bg-salmony-0'} relative min-h-screen overflow-x-hidden flex flex-col`}>
      <Header siteTitle={data.site.siteMetadata.title} />
      <main className="mx-auto flex-1-0-auto w-full">{children}</main>

      {/* <p>
        Icons made by{' '}
        <a href="https://www.flaticon.com/authors/freepik" title="Freepik">
          Freepik
        </a>{' '}
        from{' '}
        <a href="https://www.flaticon.com/" title="Flaticon">
          {' '}
          www.flaticon.com
        </a>
      </p> */}
      <img
        className="rock-me-senpai h-24 md:h-16 mt-4 md:mt-16 mx-auto -mb-4 md:-mb-8 mt-12"
        src={Logo}
        alt="icon baby feet"
      />
      <footer
        className="w-full bg-fixed bottom-0 mt-8 md:mt-12 pt-8 flex-shrink-0"
        style={{ position: 'inherit' }}
      >
        <div className="w-full curved z-10">
          {/* <p>Retrouvez nous sur tous les réseaux</p> */}
          <div className="flex flex-col md:flex-row max-w-screen-xl z-20 mx-auto">
            <div id="social-media" className="flex flex-col md:w-2/5 md:pr-12 z-20 justify-around">
              <a
                href="https://www.facebook.com/bebeaupaysdusommeil"
                className="block text-3xl md:text-xl text-custom-purple w-full text-center md:text-right md:mt-2"
              >
                <img src={facebook} alt="facebook logo" className="inline h-6 mr-1" />
                <span className="h-24 font-muli">Facebook</span>
              </a>
              <a
                href="https://www.instagram.com/p/B7bO9noC4zs"
                className="block text-3xl md:text-xl text-custom-purple w-full text-center md:text-right md:mt-2"
              >
                <img src={instagram} alt="instagram logo" className="inline h-6 mr-2" />
                <span className="h-24 font-muli">Instagram</span>
              </a>
              <Link
                to="/contact-form"
                className="block text-3xl md:text-xl text-custom-purple w-full text-center md:text-right md:mt-2"
              >
                <img src={email} alt="email logo" className="inline h-6 mr-2" />
                <span className="h-24 font-muli">Contact</span>
              </Link>
            </div>

            <a
              id="family-sleep-institute"
              className="flex flex-col justify-around md:w-1/5 z-20 md:text-left text-3xl space-y-2 pb-4"
              href="https://familysleepinstitute.com/"
            >
              <p className="text-2xl md:text-base font-muli items-center mx-auto text-center xl:flex-row xl:w-5/6 xl:text-xl">
                Certified by Family Sleep Institute
              </p>
              <img
                src={familySleepInstitute}
                alt="Family Sleep Institute's logo"
                className="h-32 object-contain"
              />
            </a>

            <div
              id="legal-links"
              className="flex flex-col justify-around md:w-2/5 z-20 text-center md:text-left text-3xl space-y-2 pb-4 ml-4"
            >
              <a
                href="/certifications.pdf"
                className="text-2xl md:text-base font-muli xl:flex-row xl:w-1/2 xl:text-xl"
                target="_blank"
              >
                Mes certificats
              </a>
              <a
                href="/mentions_legales.pdf"
                className="text-2xl md:text-base font-muli xl:flex-row xl:w-1/2 xl:text-xl"
                target="_blank"
              >
                Mentions légales
              </a>
              <a
                href="/conditions_generales_vente.pdf"
                className="text-2xl md:text-base font-muli xl:flex-row xl:w-1/2 xl:text-xl"
                target="_blank"
              >
                Conditions générales de vente
              </a>
              <a
                href="/avis_de_non_responsabilité.pdf"
                className="text-2xl md:text-base font-muli xl:flex-row xl:w-1/2 xl:text-xl"
                target="_blank"
              >
                Avis de non responsabilité
              </a>
              <a
                href="/politique_de_confidentialite.pdf"
                className="text-2xl md:text-base font-muli xl:flex-row xl:w-1/2 xl:text-xl"
                target="_blank"
              >
                Politique de confidentialité
              </a>
            </div>
          </div>
        </div>
      </footer>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
